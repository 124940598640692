import { useStaticQuery, graphql } from "gatsby";

export const useWhoWeAreCollaboratorsSectionHeading = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(template: { templateName: { eq: "Page Who We Are" } }) {
        whoWeAre {
          whoWeAreCollaborators {
            copy
            heading
            showSection
          }
        }
      }
    }
  `);
  return data.wpPage.whoWeAre.whoWeAreCollaborators;
};
