import { useStaticQuery, graphql } from "gatsby";

export const useWhoWeAreScienceCommunitySection = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(template: { templateName: { eq: "Page Who We Are" } }) {
        whoWeAre {
          whoWeAreScienceCommunity {
            copy
            heading
            readMoreButtonLink
            showSection
          }
        }
      }
    }
  `);
  return data.wpPage.whoWeAre.whoWeAreScienceCommunity;
};
