import { useStaticQuery, graphql } from "gatsby";

export const useWhoWeAreLeadershipSectionMemberStaff = () => {
  const data = useStaticQuery(graphql`
    {
      allWpIsrLeadershipMember(
        filter: { isrLeadershipMember: { type: { eq: "Staff" } } }
      ) {
        nodes {
          isrLeadershipMember {
            bio
            name
            title
            type
            picture {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(formats: [AUTO, WEBP, AVIF])
                }
              }
            }
          }
        }
      }
    }
  `);
  return data.allWpIsrLeadershipMember.nodes;
};
