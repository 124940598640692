import { useStaticQuery, graphql } from "gatsby";
export const useWhoWeAreHeadingSection = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(template: { templateName: { eq: "Page Who We Are" } }) {
        whoWeAre {
          whoWeAreHeadingSection {
            copy
            heading
          }
        }
      }
    }
  `);
  return data.wpPage.whoWeAre.whoWeAreHeadingSection;
};
