import { useStaticQuery, graphql } from "gatsby";
export const useWhoWeAreMembersSectionMembers = () => {
  const data = useStaticQuery(graphql`
    {
      allWpIsrMember {
        nodes {
          isrMember {
            website
            logo {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(formats: [AUTO, WEBP, AVIF])
                }
              }
            }
          }
        }
      }
    }
  `);
  return data.allWpIsrMember.nodes;
};
