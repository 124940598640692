import React from "react";
import Layout from "../components/layout/layout";
import Header from "../components/Header/Header";
import WhoWeAreHeaderSection from "../components/WhoWeAre/WhoWeAreSection/WhoWeAreHeaderSection";
import MembersSection from "../components/WhoWeAre/MembersSection/MembersSection";
import CollaboratorsSection from "../components/WhoWeAre/CollaboratorsSection/CollaboratorsSection";
import ScienceCommunitySection from "../components/WhoWeAre/ScienceCommunitySection/ScienceCommunitySection";
import LeadershipSection from "../components/WhoWeAre/LeadershipSection/LeadershipSection";

import Footer from "../components/Footer/Footer";
import PartnersSection from "../components/WhoWeAre/PartnersSection/PartnersSection";

const WhoWeAre = () => {
  return (
    <Layout>
      <Header />
      <WhoWeAreHeaderSection />
      <MembersSection />
      <CollaboratorsSection />
      <ScienceCommunitySection />
      <LeadershipSection />
      <PartnersSection />
      <Footer />
    </Layout>
  );
};

export default WhoWeAre;
