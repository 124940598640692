import { useStaticQuery, graphql } from "gatsby";

export const useWhoWeAreLeadershipSectionMembersExec = () => {
  const data = useStaticQuery(graphql`
    {
      allWpIsrLeadershipMember(
        filter: { isrLeadershipMember: { type: { eq: "Executive Board" } } }
        sort: { fields: isrLeadershipMember___lastName, order: ASC }
      ) {
        nodes {
          isrLeadershipMember {
            name
            firstName
            lastName
            suffix
            title
            type
            bio
            picture {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(formats: [AUTO, WEBP, AVIF])
                }
              }
            }
          }
        }
      }
    }
  `);
  return data.allWpIsrLeadershipMember.nodes;
};
