import React, { useState } from "react";
import uniqueId from "lodash/uniqueId";
// import { useWhoWeAreFundersSectionCopys } from "../../../hooks/useWhoWeAre/useWhoWeAreFundersSectionCopys";
// import { useWhoWeAreFundersSectionFunderFoundingPeople } from "../../../hooks/useWhoWeAre/useWhoWeArePartnersSectionPartnersFoundingPeople";
// import { useWhoWeAreFundersSectionFunderFoundingComp } from "../../../hooks/useWhoWeAre/useWhoWeArePartnersSectionPartnersFoundingComp";
// import { useWhoWeAreFundersSectionFunderManagingPeople } from "../../../hooks/useWhoWeAre/useWhoWeArePartnersSectionPartnersManagingPeople";
// import { useWhoWeAreFundersSectionFunderManagingComp } from "../../../hooks/useWhoWeAre/useWhoWeArePartnersSectionPartnersManagingComp";
// import { useWhoWeAreFundersSectionFunderSustainingPeople } from "../../../hooks/useWhoWeAre/useWhoWeArePartnersSectionPartnersSustainingPeople";
// import { useWhoWeAreFundersSectionFunderSustainingComp } from "../../../hooks/useWhoWeAre/useWhoWeArePartnersSectionPartnersSustainingComp";
// import IsrLabel from "../../IsrLabel/IsrLabel";
import LogoToVisit from "../../LogoToVisit/LogoToVisit";
import BioCard from "../../BioCard/BioCard";
import Spacer from "../../Spacer/Spacer";
import BioModal from "../../BioModal/BioModal";
import "./PartnersSection.scss";
import { useWhoWeArePartnersSectionPartnersUncategorizedPeople } from "../../../hooks/useWhoWeAre/useWhoWeArePartnersSectionPartnersUncategorizedPeople";
import { useWhoWeArePartnersSectionPartnersUncategorizedComp } from "../../../hooks/useWhoWeAre/useWhoWeArePartnersSectionPartnersUncategorizedComp";
import { useWhoWeArePartnersSectionCopys } from "../../../hooks/useWhoWeAre/useWhoWeArePartnersSectionCopys";

const PartnersSection = () => {
  const {
    // foundingPartnershipsCopy,
    // managingPartnershipsCopy,
    // sustainingPartnershipsCopy,
    generalCopy,
    showSection,
  } = useWhoWeArePartnersSectionCopys();

  const [bioModalOpen, setModalOpen] = useState(false);
  const [selectedMemberToRead, setSelectedMemberToRead] = useState(null);

  const handleReadBioClick = async (member) => {
    await setSelectedMemberToRead(member);
    setModalOpen(true);
  };

  // const foundingPartnershipsPeople =
  //   useWhoWeAreFundersSectionFunderFoundingPeople();
  // const foundingPartnershipsComp =
  //   useWhoWeAreFundersSectionFunderFoundingComp();

  // const managingPartnershipsPeople =
  //   useWhoWeAreFundersSectionFunderManagingPeople();
  // const managingPartnershipsComp =
  //   useWhoWeAreFundersSectionFunderManagingComp();

  // const sustainingPartnershipsPeople =
  //   useWhoWeAreFundersSectionFunderSustainingPeople();
  // const sustainingPartnershipsComp =
  //   useWhoWeAreFundersSectionFunderSustainingComp();

  const uncategorizedPartnershipsPeople =
    useWhoWeArePartnersSectionPartnersUncategorizedPeople();
  const uncategorizedPartnershipsComp =
    useWhoWeArePartnersSectionPartnersUncategorizedComp();

  if (showSection)
    return (
      <>
        <section className="fundersSection">
          <div className="container">
            <h2 id="partners">Partners</h2>
            <Spacer size={10} mobileSize={10} />
            {generalCopy && (
              <div
                className="executiveBoard-p"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: generalCopy,
                }}
              />
            )}
            <div className="foundingPartnershipsContainer">
              <div className="row logoToVisitContainer">
                {/* {uncategorizedPartnershipsComp &&
                uncategorizedPartnershipsPeople &&
                (uncategorizedPartnershipsComp.length === 0 ||
                  uncategorizedPartnershipsPeople.length === 0) && (
                  <h3 className="no-data">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-info-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                    </svg>
                    There are no Partners currently
                  </h3>
                )} */}
                {uncategorizedPartnershipsComp &&
                  uncategorizedPartnershipsComp.map((item) => (
                    <LogoToVisit
                      key={uniqueId("bio-card")}
                      logo={item.isrPartner.picture}
                      url={item.isrPartner.companyorganisationWebsite}
                    />
                  ))}
              </div>
              <div className="row membersContainer">
                {uncategorizedPartnershipsPeople &&
                  uncategorizedPartnershipsPeople.map((item) => (
                    <BioCard
                      key={uniqueId("bio-card")}
                      item={item.isrPartner}
                      handleReadBioClick={handleReadBioClick}
                      hasImage={item.isrPartner.picture !== null}
                    />
                  ))}
              </div>
            </div>
            {/* <div className="foundingPartnershipsContainer">
             <IsrLabel variant="primary" color="blue" size="md">
              Founding Partnerships
            </IsrLabel>
            {foundingPartnershipsCopy && (
              <div
                className="foundingPartnerships-p"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: foundingPartnershipsCopy,
                }}
              />
            )}

            <div className=" row logoToVisitContainer">
              {foundingPartnershipsComp &&
                foundingPartnershipsComp.map((item) => (
                  <LogoToVisit
                    key={uniqueId("bio-card")}
                    logo={item.isrFunder.picture}
                    url={item.isrFunder.companyorganisationWebsite}
                  />
                ))}
            </div>
            <div className=" row membersContainer">
              {foundingPartnershipsPeople &&
                foundingPartnershipsPeople.map((item) => (
                  <BioCard
                    key={uniqueId("bio-card")}
                    item={item.isrFunder}
                    handleReadBioClick={handleReadBioClick}
                    hasImage={item.isrFunder.picture !== null}
                  />
                ))}
            </div>
          </div>
          <div className="managingPartnershipsContainer">
            <IsrLabel variant="primary" color="blue" size="md">
              Managing Partnerships
            </IsrLabel>
            {managingPartnershipsCopy && (
              <div
                className="managingPartnerships-p"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: managingPartnershipsCopy,
                }}
              />
            )}
            <div className=" row logoToVisitContainer">
              {managingPartnershipsComp &&
                managingPartnershipsComp.map((item) => (
                  <LogoToVisit
                    key={uniqueId("bio-card")}
                    logo={item.isrFunder.picture}
                    url={item.isrFunder.companyorganisationWebsite}
                  />
                ))}
            </div>
            <div className=" row membersContainer">
              {managingPartnershipsPeople &&
                managingPartnershipsPeople.map((item) => (
                  <BioCard
                    key={uniqueId("bio-card")}
                    item={item.isrFunder}
                    handleReadBioClick={handleReadBioClick}
                    hasImage={item.isrFunder.picture !== null}
                  />
                ))}
            </div>
          </div>
          <div className="sustainingPartnershipsContainer">
            <IsrLabel variant="primary" color="blue" size="md">
              Sustaining Partnerships
            </IsrLabel>
            {sustainingPartnershipsCopy && (
              <div
                className="sustainingPartnerships-p"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: sustainingPartnershipsCopy,
                }}
              />
            )}
            <div className=" row logoToVisitContainer">
              {sustainingPartnershipsComp &&
                sustainingPartnershipsComp.map((item) => (
                  <LogoToVisit
                    key={uniqueId("bio-card")}
                    logo={item.isrFunder.picture}
                    url={item.isrFunder.companyorganisationWebsite}
                  />
                ))}
            </div>
            <div className=" row membersContainer">
              {sustainingPartnershipsPeople &&
                sustainingPartnershipsPeople.map((item) => (
                  <BioCard
                    key={uniqueId("bio-card")}
                    item={item.isrFunder}
                    handleReadBioClick={handleReadBioClick}
                    hasImage={item.isrFunder.picture !== null}
                  />
                ))}
            </div> 
          </div> */}

            <Spacer size={90} mobileSize={40} />
          </div>
        </section>
        <BioModal
          toggleModal={() => {
            setModalOpen(false);
          }}
          showModal={bioModalOpen}
          modalData={selectedMemberToRead}
        />
      </>
    );
  return null;
};

export default PartnersSection;
