import React from "react";
import uniqueId from "lodash/uniqueId";
import LogoToVisit from "../../LogoToVisit/LogoToVisit";
import Spacer from "../../Spacer/Spacer";
import { useWhoWeAreMembersSectionHeading } from "../../../hooks/useWhoWeAre/useWhoWeAreMembersSectionHeading";
import { useWhoWeAreMembersSectionMembers } from "../../../hooks/useWhoWeAre/useWhoWeAreMembersSectionMembers";
import "./MembersSection.scss";

const MembersSection = () => {
  const { copy, heading, showSection } = useWhoWeAreMembersSectionHeading();
  const members = useWhoWeAreMembersSectionMembers();

  if (showSection)
    return (
      <section className="membersSection">
        <div className="container">
          {heading && (
            <>
              <h2>{heading}</h2>
              <Spacer size={10} mobileSize={10} />
            </>
          )}
          {copy && (
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: copy,
              }}
            />
          )}
          <div className="row logoToVisitContainer">
            {/* {members && members.length === 0 && !!copy && (
            <h3 className="no-data">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-info-circle-fill"
                viewBox="0 0 16 16"
              >
                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
              </svg>
              There are no members currently
            </h3>
          )} */}
            {members &&
              members.map((item) => (
                <LogoToVisit
                  key={uniqueId("member_")}
                  url={item.isrMember.website}
                  logo={item.isrMember.logo}
                />
              ))}
          </div>
          <Spacer size={40} mobileSize={20} />
          <hr size="3" className="divider grey" />
          <Spacer size={40} mobileSize={20} />
        </div>
      </section>
    );
  return null;
};

export default MembersSection;
