import React, { useState } from "react";
import uniqueId from "lodash/uniqueId";
import { useWhoWeAreLeadershipSectionCopys } from "../../../hooks/useWhoWeAre/useWhoWeAreLeadershipSectionCopys";
import { useWhoWeAreLeadershipSectionMembersCom } from "../../../hooks/useWhoWeAre/useWhoWeAreLeadershipSectionMembersCom";
import { useWhoWeAreLeadershipSectionMembersExec } from "../../../hooks/useWhoWeAre/useWhoWeAreLeadershipSectionMembersExec";
import IsrLabel from "../../IsrLabel/IsrLabel";
import BioCard from "../../BioCard/BioCard";
import Spacer from "../../Spacer/Spacer";
import BioModal from "../../BioModal/BioModal";
//import "./LeadershipSection.scss";
import { useWhoWeAreLeadershipSectionMemberStaff } from "../../../hooks/useWhoWeAre/useWhoWeAreLeadershipSectionMemberStaff";

const LeadershipSection = () => {
  const {
    committeeMembersCopy,
    executiveBoardCopy,
    staffCopy,
    showSection,
  } = useWhoWeAreLeadershipSectionCopys();
  const leadershipCommitteeMembers = useWhoWeAreLeadershipSectionMembersCom();
  const leadershipExecMembers = useWhoWeAreLeadershipSectionMembersExec();
  const leadershipStaffMembers = useWhoWeAreLeadershipSectionMemberStaff();

  const [bioModalOpen, setModalOpen] = useState(false);
  const [selectedMemberToRead, setSelectedMemberToRead] = useState(null);

  const handleReadBioClick = async (member) => {
    await setSelectedMemberToRead(member);
    setModalOpen(true);
  };

  if (showSection)
    return (
      <>
        <section className="leadership-section">
          <div className="container">
            <h2> Leadership</h2>
            <Spacer size={10} mobileSize={10} />
            <div className="aboutExecutiveBoardMembersContainer">
              {(executiveBoardCopy || leadershipExecMembers.length !== 0) && (
                <IsrLabel variant="primary" color="blue" size="md">
                  Executive Board
                </IsrLabel>
              )}
              {executiveBoardCopy && (
                <div
                  className="executiveBoard-p"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: executiveBoardCopy,
                  }}
                />
              )}

              <div className="row membersContainer">
                {leadershipExecMembers &&
                  leadershipExecMembers.map((item) => (
                    <BioCard
                      key={uniqueId("bio-card")}
                      item={item.isrLeadershipMember}
                      handleReadBioClick={handleReadBioClick}
                      hasImage={item.isrLeadershipMember.picture !== null}
                    />
                  ))}
              </div>
            </div>

            <div className="aboutComitteeMembersContainer">
              {(committeeMembersCopy ||
                leadershipCommitteeMembers.length !== 0) && (
                <IsrLabel variant="primary" color="blue" size="md">
                  Committee Members
                </IsrLabel>
              )}
              {committeeMembersCopy && (
                <div
                  className="comitteeMembers-p"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: committeeMembersCopy,
                  }}
                />
              )}

              <div className=" row membersContainer">
                {leadershipCommitteeMembers &&
                  leadershipCommitteeMembers.map((item) => (
                    <BioCard
                      key={uniqueId("bio-card")}
                      item={item.isrLeadershipMember}
                      handleReadBioClick={handleReadBioClick}
                      hasImage={item.isrLeadershipMember?.picture !== null}
                    />
                  ))}
              </div>
            </div>
            <div className="aboutStaffContainer">
              {(staffCopy || leadershipStaffMembers.length !== 0) && (
                <IsrLabel variant="primary" color="blue" size="md">
                  Staff
                </IsrLabel>
              )}
              {staffCopy && (
                <div
                  className="staffMembers-p"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: staffCopy,
                  }}
                />
              )}

              <div className=" row membersContainer">
                {leadershipStaffMembers &&
                  leadershipStaffMembers.map((item) => (
                    <BioCard
                      key={uniqueId("bio-card")}
                      item={item.isrLeadershipMember}
                      handleReadBioClick={handleReadBioClick}
                      hasImage={item.isrLeadershipMember.picture !== null}
                    />
                  ))}
              </div>
            </div>

            {(staffCopy || leadershipStaffMembers.length !== 0) && (
              <Spacer size={40} mobileSize={20} />
            )}
            <hr size="3" className="divider grey" />
            <Spacer size={40} mobileSize={20} />
          </div>
        </section>
        <BioModal
          toggleModal={() => {
            setModalOpen(false);
          }}
          showModal={bioModalOpen}
          modalData={selectedMemberToRead}
        />
      </>
    );
  return null;
};

export default LeadershipSection;
