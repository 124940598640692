import React from "react";
import { useWhoWeAreHeadingSection } from "../../../hooks/useWhoWeAre/useWhoWeAreHeadingSection";
import Spacer from "../../Spacer/Spacer";
import "./WhoWeAreHeaderSection.scss";

const WhoWeAreHeaderSection = () => {
  const { heading, copy } = useWhoWeAreHeadingSection();
  return (
    <section className="whoWeAreHeaderSection">
      <div className="container">
        {heading && (
          <>
            <h1 className="h2"> {heading}</h1>
            <Spacer size={10} mobileSize={10} />
          </>
        )}
        <hr size="3" className="divider" />
        <Spacer size={10} mobileSize={10} />
        {copy && (
          <div className="row">
            <div className="col-md-2" />
            <div
              className="col-md-8 content-wrapper"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: copy,
              }}
            />
            <div className="col-md-2" />
          </div>
        )}
        <Spacer size={40} mobileSize={20} />
        <hr size="3" className="divider grey" />
        <Spacer size={40} mobileSize={20} />
      </div>
    </section>
  );
};

export default WhoWeAreHeaderSection;
