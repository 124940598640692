import React from "react";
import IsrButton from "../../IsrButton/IsrButton";
import Spacer from "../../Spacer/Spacer";
import { useWhoWeAreScienceCommunitySection } from "../../../hooks/useWhoWeAre/useWhoWeAreScienceCommunitySection";
import IsrLink from "../../IsrLink/IsrLink";
import "./ScienceCommunitySection.scss";

const ScienceCommunitySection = () => {
  const { copy, heading, readMoreButtonLink, showSection } =
    useWhoWeAreScienceCommunitySection();
  if (showSection)
    return (
      <section className="scienceCommunitySection">
        <div className="container">
          {heading && <h2>{heading}</h2>}
          <Spacer size={10} mobileSize={10} />
          {copy && (
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: copy,
              }}
            />
          )}
          <Spacer size={30} mobileSize={10} />
          <IsrLink link={{ url: readMoreButtonLink }}>
            <IsrButton variant="primary" type="button" size="md">
              Read More
            </IsrButton>
          </IsrLink>

          <Spacer size={40} mobileSize={20} />
          <hr size="3" className="divider grey" />
          <Spacer size={40} mobileSize={20} />
        </div>
      </section>
    );
  return null;
};

export default ScienceCommunitySection;
