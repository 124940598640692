import { useStaticQuery, graphql } from "gatsby";
export const useWhoWeAreCollaboratorsSectionCollaborators = () => {
  const data = useStaticQuery(graphql`
    {
      allWpIsrCollaborator {
        nodes {
          isrCollaborator {
            website
            logo {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(formats: [AUTO, WEBP, AVIF])
                }
              }
            }
          }
        }
      }
    }
  `);
  return data.allWpIsrCollaborator.nodes;
};
