import { useStaticQuery, graphql } from "gatsby";

export const useWhoWeAreLeadershipSectionCopys = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(template: { templateName: { eq: "Page Who We Are" } }) {
        whoWeAre {
          whoWeAreLeadership {
            committeeMembersCopy
            executiveBoardCopy
            staffCopy
            showSection
          }
        }
      }
    }
  `);
  return data.wpPage.whoWeAre.whoWeAreLeadership;
};
